var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Setting")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Maintance Mode ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.maintanance_mode),callback:function ($$v) {_vm.$set(_vm.formData, "maintanance_mode", $$v)},expression:"formData.maintanance_mode"}},[(_vm.formData.maintanance_mode)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Logo","vid":"logo_file","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Logo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"logo_file","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.setFile},model:{value:(_vm.formData.logo_file),callback:function ($$v) {_vm.$set(_vm.formData, "logo_file", $$v)},expression:"formData.logo_file"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('img',{attrs:{"width":"50px","src":_vm._f("filePath")(_vm.formData.logo)}})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Title","vid":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"title","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Description","vid":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-textarea',{attrs:{"type":"text","id":"description","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mt-4"},[_vm._v("Ads Setting")])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Ads Token","vid":"ads_token","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ads Token "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"ads_token","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.ads_token),callback:function ($$v) {_vm.$set(_vm.formData, "ads_token", $$v)},expression:"formData.ads_token"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Google Analytic Token","vid":"google_analytic_token","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Analytic Token "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"google_analytic_token","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.google_analytic_token),callback:function ($$v) {_vm.$set(_vm.formData, "google_analytic_token", $$v)},expression:"formData.google_analytic_token"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Select Ads","vid":"ads_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Ads ")]},proxy:true}],null,true)},[_c('Select2',{attrs:{"options":_vm.adsList,"id":"ads_id","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.ads_id),callback:function ($$v) {_vm.$set(_vm.formData, "ads_id", $$v)},expression:"formData.ads_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }