<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loading">
                <b-row>
                    <b-col lg="12" sm="12">
                        <CCard>
                            <CCardHeader>
                                <div class="row">
                                    <div class="col-md-6">
                                        <strong>Setting</strong>
                                    </div>
                                </div>
                            </CCardHeader>
                            <CCardBody>
                                <div>
                                    <b-col lg="12" sm="12">
                                        <b-overlay :show="false">
                                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div>
                                                                <p class="m-0 mb-2" style="font-weight: bold"> Maintance Mode </p>
                                                                <b-form-checkbox v-model="formData.maintanance_mode" size="md" name="check-button" switch>
                                                                    <span v-if="formData.maintanance_mode">ON</span>
                                                                    <span v-else>OF</span>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Logo" vid="logo_file" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    Logo <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-file
                                                                    type="text"
                                                                    id="logo_file"
                                                                    @change="setFile"
                                                                    v-model="formData.logo_file"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-file>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                            <img width="50px" :src="formData.logo | filePath">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Title" vid="title" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    Title <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    type="text"
                                                                    id="title"
                                                                    v-model="formData.title"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Description" vid="description" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    Description <span class="text-danger">*</span>
                                                                </template>
                                                                <b-textarea
                                                                    type="text"
                                                                    id="description"
                                                                    v-model="formData.description"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-textarea>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <h5 class="mt-4">Ads Setting</h5>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Ads Token" vid="ads_token" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    Ads Token <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    type="text"
                                                                    id="ads_token"
                                                                    v-model="formData.ads_token"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Google Analytic Token" vid="google_analytic_token" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    Google Analytic Token <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    type="text"
                                                                    id="google_analytic_token"
                                                                    v-model="formData.google_analytic_token"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <ValidationProvider name="Select Ads" vid="ads_id" rules="">
                                                                <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="formData"
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                Select Ads
                                                                </template>
                                                                <Select2
                                                                :options="adsList"
                                                                    id="ads_id"
                                                                    rows="6"
                                                                    v-model="formData.ads_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></Select2>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-md-12 text-right">
                                                            <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">{{ saveBtnName }}</b-button>
                                                        </div>
                                                    </div>
                                                </b-form>
                                            </ValidationObserver>
                                        </b-overlay>
                                    </b-col>
                                </div>
                            </CCardBody>
                        </CCard>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      this.getData()
  },
  data () {
    return {
      saveBtnName: "Update",
      formData: {
        'logo_file': [],
        'title': null,
        'ads_token': null,
        'google_analytic_token': null,
        'description': null,
        'maintanance_mode': null,
        'ads_id': null,
      },
      editId: ''
    }
  },
  computed: {
	loading () {
		return this.$store.state.static.loading
	},
    adsList () {
        return this.$store.state.commonObj.adsList
    }
  },
  methods: {
    setFile (event) {
        this.formData.logo_file = event.target.files[0]
    },
    getData () {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/setting/show').then(response => {
            if (response.success) {
                this.formData = response.data
                this.formData.maintanance_mode = response.data.maintanance_mode == '1' ? true : false
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
        this.$store.dispatch('mutedLoad', { loading: true})
    },
    async register () {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null

        let formData = new FormData()
        Object.keys(this.formData).map(key => {
            formData.append(key, this.formData[key])
        });
        formData.append('maintanance_mode', this.formData['maintanance_mode']  == true ? 1 : 0)

        result = await RestApi.postData(baseUrl, `api/setting/update`, formData)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    async message () {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.postData(baseUrl, `api/message/update`, this.messages)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form1.setErrors(result.errors)
        }
    }
  }
}
</script>
